.calendar-container {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .calendar-container {
    height: calc(100vh - 200px);
  }
}

@media only screen and (max-width: 767px) {
  .calendar-container {
    height: calc(100vh - 300px);
  }
}

@media only screen and (max-width: 424px) {
  .calendar-container {
    height: calc(100vh - 400px);
  }
}
