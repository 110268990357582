input:checked + label {
	border-color: black;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    color: #000000;
    background-color: #ffffff;
    outline: solid 2px #000000
}

input:hover:checked + label {
    background-color: #ffffff;
}