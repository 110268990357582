@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body {
  background-color: #f5f5f5;
}

/* React big calander styles */

.calander-component .rbc-toolbar-label {
  text-align: right !important;
  font-weight: bold;
}

.rbc-btn-group button {
  border-color: #000000 !important;
}

.calander-component .rbc-event {
  background-color: transparent;
  border: 1px solid #000000;
  cursor: pointer;
  color: #000000;
}

.hidden-header {
  display: none;
}

@media screen and (max-width: 768px) {
  .calander-component .rbc-event {
    font-size: 12px;
  }
}

@media screen and (max-width: 366px) {
  .calander-component .rbc-toolbar-label {
    text-align: center !important;
    font-weight: bold;
    margin-top: 0.33rem;
  }
}

.info-box {
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-box-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.info-box-content {
  font-size: 14px;
  line-height: 1.5;
}
